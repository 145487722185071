import { Injectable } from '@angular/core';
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import {firebaseConfig} from '../../environment/environment'
import {getDatabase} from 'firebase/database'
import { getStorage } from 'firebase/storage'
import {getAuth} from 'firebase/auth'
import {getFirestore} from 'firebase/firestore'
const app = initializeApp(firebaseConfig);
const db =  getDatabase(app);
// const anal = getAnalytics(app);
const storage = getStorage(app);
const auth =  getAuth(app);

const firestore = getFirestore(app)

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  constructor() { }
  
  getApp(){
    return app
  }

  getfirestore(){
    return firestore
  }

  getAuth(){
    return auth
  }

 async  isAuthenticated():Promise<boolean>{
    return new Promise((resolve, reject) => {
      auth.onAuthStateChanged(user => {
        if (user) {
          resolve(true); // User is authenticated
        } else {
          resolve(false); // User is not authenticated
        }
      });
    });
  }

  getStorage(){
    return storage
  }

  getDatabase(){
    return db
  }

  // getAnaytics(){
  //   return anal
  // }
}
