export const firebaseConfig={
    
  apiKey: "AIzaSyDPLU8GQn-tDxklNlD9I7x4LEW_94RC9CA",
  authDomain: "splintzer-com.firebaseapp.com",
  databaseURL: "https://splintzer-com-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "splintzer-com",
  storageBucket: "splintzer-com.appspot.com",
  messagingSenderId: "612268820999",
  appId: "1:612268820999:web:c8340d5d4db92d06b738a2",
  measurementId: "G-D0FV53L7Q6"
}

